import { render, staticRenderFns } from "./MyTechno.vue?vue&type=template&id=0ab1ffda&scoped=true"
import script from "./MyTechno.vue?vue&type=script&lang=js"
export * from "./MyTechno.vue?vue&type=script&lang=js"
import style0 from "./MyTechno.vue?vue&type=style&index=0&id=0ab1ffda&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ab1ffda",
  null
  
)

export default component.exports